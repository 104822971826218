<template>
  <div>
    <i>
      If your circumstances change, please contact us
    </i>
    <v-container v-if="loading < 1" fluid grid-list-xl class="p-0">
      <v-layout wrap>
        <v-flex xs12 sm4>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-flex>
        <v-flex xs12 sm4>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-flex>
        <v-flex xs12 sm4>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-flex>
        <v-flex xs12 sm6>
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-flex>
        <v-flex xs12 sm6>
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container v-else fluid grid-list-xl class="p-0">
      <v-layout wrap>
        <v-flex xs12 sm4 class="flex-card" height="100%">
          <v-card class="grow">
            <v-card-title>
              Account type
            </v-card-title>
            <v-card-text v-if="payoutAccount.account_type === 1">
              Company<br />
              VAT: {{ payoutAccount.is_taxable ? 'Yes' : 'No' }}
            </v-card-text>
            <v-card-text v-else>
              Personal (B-income)<br />
              <a
                href="https://skat.dk/skat.aspx?oid=2234739"
                target="_blank"
                class="font-italic"
              >
                Read more at skat.dk
              </a>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 class="flex-card" height="100%">
          <v-card class="grow">
            <v-card-title>Transfers</v-card-title>
            <v-card-text v-if="isTransfersEnabled">
              <v-icon color="success" medium class="mr-3"
                >fal fa-check-circle</v-icon
              >
              <span class="success--text">Activated</span>
            </v-card-text>
            <v-card-text v-else>
              <v-icon color="error" medium class="mr-3"
                >fal fa-exclamation-circle</v-icon
              >
              <span class="error--text">Deactivated</span>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 class="flex-card" height="100%">
          <v-card class="grow">
            <v-card-title>Payout</v-card-title>
            <v-card-text v-if="payoutAccount.payouts_enabled_at">
              <v-icon color="success" medium class="mr-3"
                >fal fa-check-circle</v-icon
              >
              <span class="success--text">Activated</span>
            </v-card-text>
            <v-card-text v-else>
              <v-icon color="error" medium class="mr-3"
                >fal fa-exclamation-circle</v-icon
              >
              <span class="error--text">Deactivated</span>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 class="flex-card" height="100%">
          <v-card class="grow">
            <v-card-title class="pb-0">
              Details
            </v-card-title>
            <v-card-text>
              All required information regarding your payout account has to be
              filled out at Stripe.

              <div class="text-center">
                <v-btn
                  color="primary"
                  class="mt-3"
                  :outlined="
                    isTransfersEnabled && payoutAccount.payouts_enabled_at
                  "
                  :loading="loadingLink"
                  @click="goToAccountPage()"
                >
                  {{
                    isTransfersEnabled && payoutAccount.payouts_enabled_at
                      ? "Edit information"
                      : "Fill out information"
                  }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 class="flex-card" height="100%">
          <v-card class="grow">
            <v-card-title class="pb-0">
              Bank details
            </v-card-title>
            <!--New user - Never added a bank account-->
            <v-card-text v-if="bankAccountInfo === null">
              Add the bank account, that will receive your payouts.
              Only Stripe saves this information.
              <div class="text-center">
                <v-btn
                  color="success"
                  class="mt-3"
                  @click="showCreateBankAccountDialog = true"
                >
                  Add bank account
                </v-btn>
              </div>
            </v-card-text>

            <!--Have added bank account/Needs to edit-->
            <v-card-text v-if="bankAccountInfo !== null" class="grow">
              <v-list two-line dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Bank</v-list-item-title>
                    <v-list-item-subtitle
                      v-html="bankAccountInfo.bank_name"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <!--<v-list-item>-->
                <!--<v-list-item-content>-->
                <!--<v-list-item-title>Land</v-list-item-title>-->
                <!--<v-list-item-subtitle v-html="bankAccountInfo.country"></v-list-item-subtitle>-->
                <!--</v-list-item-content>-->
                <!--</v-list-item>-->

                <!--<v-list-item>-->
                <!--<v-list-item-content>-->
                <!--<v-list-item-title>Valuta</v-list-item-title>-->
                <!--<v-list-item-subtitle v-html="bankAccountInfo.currency"></v-list-item-subtitle>-->
                <!--</v-list-item-content>-->
                <!--</v-list-item>-->

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>IBAN</v-list-item-title>
                    <v-list-item-subtitle
                      v-html="'****' + bankAccountInfo.last4"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Status</v-list-item-title>
                    <v-list-item-subtitle
                      v-html="bankAccountInfo.status"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn
                v-if="bankAccountInfo !== null"
                text
                color="primary"
                @click="showCreateBankAccountDialog = true"
                >Change bank</v-btn
              >
            </v-card-actions>

            <payout-bank-account-dialog
              v-model="showCreateBankAccountDialog"
              :payout-account="payoutAccount"
              @success="refresh()"
            ></payout-bank-account-dialog>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import PayoutBankAccountDialog from "../dialogs/PayoutBankAccountDialog";

export default {
  components: {
    PayoutBankAccountDialog
  },
  data: () => ({
    loading: 0,
    payoutAccount: {},
    loadingLink: false,
    externalAccounts: [],
    showCreateBankAccountDialog: false
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    bankAccountInfo() {
      if (this.externalAccounts.length > 0) {
        return this.externalAccounts[0];
      }

      return null;
    },
    isTransfersEnabled() {
      return this.payoutAccount && this.payoutAccount.transfers_enabled_at;
    }
  },
  methods: {
    ...mapActions("influencer", [
      "getPayoutAccount",
      "createPayoutAccountLink",
      "getExternalPayoutAccount"
    ]),

    ...mapActions("loading", ["setPageLoading"]),

    getExternalAccounts() {
      const params = {
        payout_account_id: this.payoutAccount.uuid
      };

      this.getExternalPayoutAccount(params).then(response => {
        this.externalAccounts = response.data;
        this.loading++;
      }).catch(() => {
        this.setSnackError("Something went wrong");
      });
      this.loading++;
    },
    goToAccountPage() {
      this.loadingLink = true;

      let params = {
        account_onboarding: false,
        payout_account_id: this.payoutAccount.uuid
      };

      this.createPayoutAccountLink(params)
        .then(response => {
          window.location.href = response.url;
        })
        .catch(() => {
          this.loadingLink = false;
          this.setSnackError("Something went wrong");
        });
    },
    refresh() {
      let activePayoutAccounts = this.user.influencer.payout_accounts.filter(
          item => item.active
      );

      this.payoutAccount = this.cloneDeep(activePayoutAccounts[0]);

      this.getExternalAccounts();
    }
  },
  mounted() {
    this.refresh();
  }
};
</script>
