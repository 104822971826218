<template>
  <v-dialog v-model="showing" width="500">
    <v-card v-if="payoutAccount">
      <v-card-title class="pb-0">
        <div>
          <div class="headline">Add bank account</div>
          <span class="subtitle-1 light-grey"
            >Make sure all details are correct</span
          >
        </div>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="iban"
          label="IBAN"
          hint="For example: DK89370400440532013000 (if DK)"
          persistent-hint
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showing = false">
          Cancel
        </v-btn>
        <v-btn color="success" :loading="loading" @click="save()">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    payoutAccount: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    iban: ""
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    ...mapActions("influencer", ["createExternalPayoutAccount"]),

    save() {
      this.loading = true;
      const params = {
        payout_account_id: this.payoutAccount.uuid,
        iban: this.iban
      };
      this.createExternalPayoutAccount(params)
        .then(response => {
          this.loading = false;
          this.iban = "";
          this.showing = false;
          this.setSnackSuccess("Bank account added");
          this.$emit("success", response);
        })
        .catch(() => {
          this.loading = false;
          this.setSnackError("Something went wrong");
        });
    }
  }
};
</script>
